'use strict'


const _ = require('lodash')
const coreUtils = require('santa-core-utils')

function fixCompHeight(pageJson, component) {
    if (component.componentType === 'wysiwyg.viewer.components.ItunesButton') {
        component.layout.height = Math.round(component.layout.width / 2.75)
    }
}

function getComponentProperties(pageJson, comp) {
    return pageJson.data.component_properties[comp.propertyQuery]
}

function getComponentData(pageJson, comp) {
    return comp.dataQuery && pageJson.data.document_data[comp.dataQuery.replace('#', '')]
}

function setComponentProperties(pageJson, comp, propertyItem) {
    pageJson.data.component_properties[comp.propertyQuery] = propertyItem
}

function setComponentData(pageJson, comp, dataItem, uniqueIdGenerator) {
    const newId = uniqueIdGenerator.getUniqueId('dataItem', '-')

    comp.dataQuery = `#${newId}`
    dataItem.id = newId
    pageJson.data.document_data[newId] = dataItem
}

function findCompHeight(comp) {
    if (comp.components.length > 0) {
        const compsBottomY = _.map(comp.components, component => component.layout.y + component.layout.height)
        return _.max(compsBottomY)
    }
    return 0
}

function findFooterY(pageJson) {
    const pagesContainer = _.find(pageJson.structure.children, {id: 'PAGES_CONTAINER'})
    return pagesContainer.layout.y + pagesContainer.layout.height
}

function addDefaultLayout(pageJson, comp) {
    if (comp.id === 'SITE_FOOTER' && !comp.layout) {
        comp.layout = {
            anchors: [],
            fixedPosition: false,
            height: findCompHeight(comp),
            rotationInDegrees: 0,
            scale: 1,
            width: 320,
            x: 0,
            y: findFooterY(pageJson)
        }
    }
}

function addDefaultData(pageJson, comp, uniqueIdGenerator) {
    if (getComponentData(pageJson, comp)) {
        return
    }

    switch (comp.componentType) {
        case 'wysiwyg.viewer.components.svgshape.SvgShape':
            setComponentData(pageJson, comp, {
                link: null,
                type: 'SvgShape'
            }, uniqueIdGenerator)
            break
        case 'wysiwyg.viewer.components.WFacebookComment':
            setComponentData(pageJson, comp, {
                type: 'WFacebookComment',
                urlFormat: 'hashBang'
            }, uniqueIdGenerator)
            return
        case 'wysiwyg.viewer.components.WFacebookLike':
            setComponentData(pageJson, comp, {
                type: 'WFacebookLike',
                urlFormat: 'hashBang'
            }, uniqueIdGenerator)
            return
        default:
            break
    }
}

function fixCompData(pagesJson, comp) {
    const data = getComponentData(pagesJson, comp)
    if (!data) {
        return
    }
    switch (comp.componentType) {
        case 'wysiwyg.viewer.components.ClipArt':
            data.title = ''
            return
        case 'wysiwyg.viewer.components.FacebookShare':
            data.urlFormat = data.urlFormat || 'hashBang'
            return
        case 'wysiwyg.viewer.components.VKShareButton':
            data.urlFormat = data.urlFormat || 'hashBang'
            return
        case 'wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt':
            data.urlFormat = data.urlFormat || 'hashBang'
            return
        case 'wysiwyg.viewer.components.WTwitterTweet':
            data.urlFormat = data.urlFormat || 'hashBang'
            return
        case 'wysiwyg.viewer.components.WFacebookComment':
            data.urlFormat = data.urlFormat || 'hashBang'
            return
        case 'wysiwyg.viewer.components.WFacebookLike':
            data.urlFormat = data.urlFormat || 'hashBang'
            return
    }
}

function validationAnchorComp(screenWidth, pageJson, comp) {
    if (comp.componentType === 'wysiwyg.common.components.anchor.viewer.Anchor') {
        comp.skin = 'AutoWidthAnchorSkin'
        delete comp.styleId
        if (screenWidth) {
            comp.layout.width = screenWidth
        } else {
            comp.layout.docked = {
                left: {
                    vw: 0,
                    px: 0
                },
                right: {
                    vw: 0,
                    px: 0
                }
            }
        }
    }
}

function addDefaultProperties(pageJson, comp) {
    if (getComponentProperties(pageJson, comp)) {
        return
    }

    switch (comp.componentType) {
        case 'wysiwyg.viewer.components.menus.DropDownMenu':
        {
            setComponentProperties(pageJson, comp, {
                alignButtons: 'center',
                alignText: 'center',
                sameWidthButtons: false,
                moreButtonLabel: 'More',
                moreItemHeight: 15,
                stretchButtonsToMenuWidth: true,
                type: 'HorizontalMenuProperties'
            })
            break
        }
        case 'wysiwyg.viewer.components.mobile.TinyMenu':
        {
            const newId = 'TINY_MENU'
            comp.propertyQuery = newId
            setComponentProperties(pageJson, comp, {
                direction: 'left',
                type: 'TinyMenuProperties',
                id: newId,
                metaData: {isPreset: false, schemaVersion: '1.0', isHidden: false}
            })
            break
        }
        default:
            break
    }
}

/**
 * this is caching the properties by comp id, so that we can access it in mobile in case there are missing properties
 * @param cache
 * @param pageJson
 * @param comp
 */
function cacheComponentPropertiesByCompId(cache, pageJson, comp) {
    cache.properties[comp.id] = getComponentProperties(pageJson, comp)
}

function fixMissingPropertiesInMobile(cache, pageJson, comp) {
    if (!getComponentProperties(pageJson, comp) && cache.properties[comp.id]) {
        setComponentProperties(pageJson, comp, cache.properties[comp.id])
    }
}

function isMobileDisplayedModeMissing(comp, mobileDisplayedModeId) {
    const modeDefinitions = _.get(comp, 'modes.definitions')

    return !_.some(modeDefinitions, {modeId: mobileDisplayedModeId})
}

function getHoverModeId(comp) {
    const modeDefinitions = _.get(comp, 'modes.definitions')

    return _.find(modeDefinitions, {type: coreUtils.siteConstants.COMP_MODES_TYPES.HOVER}).modeId
}

function fixCompProps(pageJson, comp) {
    const props = getComponentProperties(pageJson, comp)
    if (!comp.propertyQuery || !props) {
        return
    }

    switch (comp.componentType) {
        case 'wysiwyg.viewer.components.WFacebookLike':
            if (!props.language) {
                props.language = 'en'
            }
            break
        case 'wysiwyg.viewer.components.PayPalButton':
            if (!props.language) {
                props.language = 'en'
            }
            break
        case 'wysiwyg.viewer.components.GoogleMap':
            if (!props.language) {
                props.language = 'en'
            }
            break
        case 'wysiwyg.viewer.components.WRichText':
            if (props.type !== 'WRichTextProperties') {
                delete comp.propertyQuery
            } else if (props.minHeight === 0) {
                props.packed = true
            }
            break
        case 'wysiwyg.viewer.components.HoverBox':
            if (props.mobileDisplayedModeId && isMobileDisplayedModeMissing(comp, props.mobileDisplayedModeId)) {
                props.mobileDisplayedModeId = getHoverModeId(comp)
            }
            break
        default:
            break
    }
}

function runOnAllComps(pageJson, comps, funcs) {
    _.forEach(comps, comp => {
        _.forEach(funcs, func => {
            func(pageJson, comp)
        })
        if (comp.components) {
            runOnAllComps(pageJson, comp.components, funcs)
        }
    })
}

function fixDesktopComps(pageJson, cache, comps, uniqueIdGenerator) {
    runOnAllComps(pageJson, comps, [
        fixCompHeight,
        _.partial(addDefaultData, _, _, uniqueIdGenerator),
        addDefaultProperties,
        fixCompProps,
        fixCompData,
        _.partial(validationAnchorComp, null), // use docked
        _.partial(cacheComponentPropertiesByCompId, cache)
    ])
}

function findMissingJsonAnchors(context, pageJson, comp) {
    context.pageContainsStructuresWithoutJsonAnchors = context.pageContainsStructuresWithoutJsonAnchors || !comp.layout || !comp.layout.anchors
}

function removeJsonAnchors(pageJson, comp) {
    if (comp.layout) {
        delete comp.layout.anchors
    }
}

function fixMobileComps(pageJson, cache, comps, uniqueIdGenerator) {
    const context = {
        pageContainsStructuresWithoutJsonAnchors: false
    }

    runOnAllComps(pageJson, comps, [
        fixCompHeight,
        _.partial(addDefaultData, _, _, uniqueIdGenerator),
        addDefaultProperties,
        fixCompProps,
        fixCompData,
        addDefaultLayout,
        _.partial(validationAnchorComp, 320),
        _.partial(fixMissingPropertiesInMobile, cache),
        _.partial(findMissingJsonAnchors, context)
    ])

    if (context.pageContainsStructuresWithoutJsonAnchors) {
        runOnAllComps(pageJson, comps, [removeJsonAnchors])
    }
}

/**
 * @exports utils/dataFixer/plugins/compFixer
 * @type {{exec: exec}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        const structureData = pageJson.structure
        if (structureData) {
            const cache = {
                properties: {}
            }

            const desktopComps = structureData.components || structureData.children
            const mobileComps = structureData.mobileComponents
            const uniqueIdGenerator = magicObject.dataFixerUtils.uniqueIdGenerator

            if (desktopComps) {
                fixDesktopComps(pageJson, cache, desktopComps, uniqueIdGenerator)
            }
            if (mobileComps) {
                fixMobileComps(pageJson, cache, mobileComps, uniqueIdGenerator)
            }
        }
    }
}
